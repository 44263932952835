/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/dot-notation */
import React, { useEffect } from 'react';
import { SessionProvider, getSession } from 'next-auth/react';
import type { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Session } from 'next-auth';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { hotjar } from 'react-hotjar';
import Script from 'next/script';
import '@hurtigruten/design-system-components/dist/styles.css';
import {
  OptimizelyProvider,
  setLogLevel,
  ReactSDKClient,
  createInstance
} from '@optimizely/react-sdk';
import cookie from 'cookie';
import { TrackJS } from 'trackjs';

import { SiteDataProvider } from '@context/DataContext';
import { StateProvider } from '@context/StateProvider';
import { XrayProvider } from '@context/XrayProvider';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import '@styles/nprogress.css';
import '@styles/globals.css';
import MegaNav from '@components/megaNav/MegaNav';
import { UserExpeditionsProvider, UserProvider } from '@context/UserProvider';
import { useLocale, useIsBookingFlow } from '@hooks';
import { isFeatureEnabled, pickFirst, trackEvent } from '@utils';
import {
  hasActiveChat,
  indicateShowChat,
  indicateHideChat
} from '@utils/brightPattern';
import CrimtanConsentScripts from '@components/CrimtanConsentScripts';
import { InfoBanner } from '@components/molecules';
import { isLocale } from '@utils/typeGuards/locale';
import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';
import { mapLocaleToContenfulFormat } from '@src/utils/locale/mappers';
import { getFlags } from '@utils/featureFlags';
import { getFooter } from '@content/requests/getFooter';
import { getGlobalInfoBanner } from '@content/requests/getGlobalInfoBanner';
import { getHeaderRibbonQuery } from '@content/requests/contentApi/getHeaderRibbon';
import { getMegaNavQuery } from '@content/requests/contentApi/getMegaNav';
import { defaultLocale } from '@hooks/useLocale';
import {
  createUserinfoEvent,
  createVirtualPageView
} from '@src/utils/analytics/eventBuilder';
import {
  mapLocaleToCruisesUri,
  mapLocaleToOffersUri
} from '@src/utils/mappers/uriMappers';
import addOptinMonsterScript from '@src/utils/optinMonster';
import { isValidWebsiteCountryCode } from '@src/utils/typeGuards/isValidWebsiteCountryCode';
import ContentfulXrayControls from '@components/contentful/ContentfulXrayControls';
import { sha256 } from '@src/utils/webCrypto';
import { MetaRobots } from '@components/seo/MetaRobots';
import { Wrapper } from '@components/Wrapper';
import {
  isEnabledDestination,
  isNewDestinationMapEnabled
} from '@src/utils/abTestHelpers';
import { normalizeUrl } from '@utils/normalizeUrl';

const sharedEnvironmentVariables = getSharedEnvironmentVariables();

const optimizelyClient: ReactSDKClient = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK ?? '',
  eventBatchSize: 100,
  eventFlushInterval: 3000,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 1000 * 60 * 20
  }
});

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  void import('../mock/msw');
}

// AXE
const isServerSideRendered = () => typeof window === 'undefined';

if (
  !TrackJS.isInstalled() &&
  sharedEnvironmentVariables.TRACKJS_TOKEN &&
  sharedEnvironmentVariables.TRACKJS_APP
) {
  TrackJS.install({
    token: sharedEnvironmentVariables.TRACKJS_TOKEN,
    application: sharedEnvironmentVariables.TRACKJS_APP,
    enabled: sharedEnvironmentVariables.TRACKJS_ENABLE === '1'
  });
}

if (process.env.NODE_ENV !== 'production' && !isServerSideRendered()) {
  void import('react-dom').then((ReactDOM) => {
    void import('@axe-core/react').then((axe) => {
      void axe.default(React, ReactDOM, 1000, {});
    });
  });
}

NProgress.configure({ showSpinner: false });

// Progressbar
Router.events.on('routeChangeStart', (_, { shallow }) => {
  if (!shallow) NProgress.start();
});
Router.events.on('routeChangeComplete', (_, { shallow }) => {
  if (!shallow) NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

function NellieApp({ Component, pageProps }: AppProps) {
  const { localisedUrl } = pageProps as {
    localisedUrl: string;
    clientCountryCode: TWebsiteCountryCode | null;
  };
  const router = useRouter();
  const { pathname, route, asPath } = router;
  const locale = useLocale();
  const featureFlags = getFlags();
  let hasViewedDestination = false;
  let hasViewedNewDestinationMap = false;

  const isHotjarEnabled = isFeatureEnabled('isHotjarEnabled');
  const isXrayEnabled = isFeatureEnabled('contentfulXRay');
  const isBookingFlow = useIsBookingFlow({});
  const isProd = process?.env?.NODE_ENV === 'production'; // also includes staging

  const hiddenHeaderPaths = ['/password', '/500', 'embeddedPaymentResponse'];
  const showHeader = !hiddenHeaderPaths.some((path) => pathname.includes(path));

  const isStaging = sharedEnvironmentVariables.WEBSITE_URL?.includes('staging');

  const fixedHeader = !isBookingFlow;

  let showXRay = false;
  let userId = '';
  if (typeof window !== 'undefined') {
    showXRay = localStorage?.getItem('XRAY') === 'true';
    const cookies = cookie.parse(document.cookie);

    userId =
      process.env.NODE_ENV === 'production' || isStaging
        ? cookies['hx_optimizely']
        : '123local456';
  }

  if (
    typeof window !== 'undefined' &&
    route.startsWith(`/destinations/`) &&
    isEnabledDestination(asPath)
  ) {
    hasViewedDestination = true;
  }

  if (
    typeof window !== 'undefined' &&
    route.startsWith(`/destinations/`) &&
    isNewDestinationMapEnabled(asPath)
  ) {
    hasViewedNewDestinationMap = true;
  }

  // OptinMonster
  useEffect(() => {
    addOptinMonsterScript();
  }, []);

  // Google Tag Manager Virtual Page View
  useEffect(() => {
    trackEvent(
      createVirtualPageView({
        locale,
        pageType: router.pathname
      })
    );
    void (async () => {
      const session = await getSession();
      if (session) {
        const { user } = session;
        if (
          user?.email !== undefined &&
          typeof crypto.subtle?.digest === 'function'
        ) {
          void sha256(user.email || undefined).then((hash) => {
            trackEvent(createUserinfoEvent(hash ?? ''));
          });
        }
      }
    })();
  }, [router.pathname]);

  useEffect(() => {
    if (
      route.startsWith(`/${mapLocaleToCruisesUri(locale)}`) ||
      route.startsWith(`/${mapLocaleToOffersUri(locale)}`) ||
      hasActiveChat()
    ) {
      indicateShowChat();
    } else {
      indicateHideChat();
    }
  }, [route]);

  useEffect(() => {
    if (
      !isHotjarEnabled ||
      pathname.startsWith(`/${mapLocaleToCruisesUri(locale)}/[cruise]/booking`)
    ) {
      return;
    }

    hotjar.initialize(
      process.env.NODE_ENV === 'production' ? 2707692 : 2725725,
      6
    );
    hotjar.event('NPS');
  }, []);

  let { page } = router.query;
  page = page ? `?page=${pickFirst(page)}` : '';

  let [localisedPath] = localisedUrl.split('?');
  localisedPath = localisedPath.endsWith('/')
    ? localisedPath
    : `${localisedPath}/`;
  const baseUrl = sharedEnvironmentVariables.WEBSITE_URL ?? '/';
  const prefixedBaseUrl = normalizeUrl(baseUrl);
  const canonicalUrl = `${prefixedBaseUrl}${locale}${localisedPath}${page}`;

  // Optzy logging
  if (process?.env?.NODE_ENV === 'development') {
    setLogLevel('info');
  }
  if (isStaging) {
    setLogLevel('debug');
  }
  if (isProd && !isStaging) {
    setLogLevel('error');
  }

  useEffect(() => {
    void optimizelyClient.onReady();
  }, []);

  const optzyWebID =
    process.env.NODE_ENV === 'production' || isStaging
      ? 25353130483
      : 28346281020;

  return (
    <UserProvider>
      <UserExpeditionsProvider>
        <Script
          src={`//cdn.optimizely.com/js/${optzyWebID}.js`}
          strategy="beforeInteractive"
        />
        <Script strategy="beforeInteractive">
          {`
            window.optimizely=window.optimizely || [];
            window.optimizely.push({
              type: "holdEvents"
            });
          `}
        </Script>
        {isProd && (
          <>
            <Script
              src="https://cdn.c360a.salesforce.com/beacon/c360a/be9b04da-4f57-42ca-a440-40e9a5eb8b5d/scripts/c360a.min.js"
              strategy="afterInteractive"
            />
            <Script id="google-tag-manager" strategy="afterInteractive">
              {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://tr.travelhx.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WHHWW57C');
          `}
            </Script>
          </>
        )}
        <Script strategy="afterInteractive">
          {`
            window.optimizely=window.optimizely || [];
            window.optimizely.push({
              type: "user",
              attributes: {
                locale: '${locale}',
                HX_Devices: '${
                  typeof window !== 'undefined' &&
                  window.matchMedia('(hover: none)').matches
                    ? 'mobile'
                    : 'desktop'
                }'
              }
            });
          `}
        </Script>
        <SessionProvider session={(pageProps as { session: Session }).session}>
          <StateProvider>
            <SiteDataProvider defaultData={pageProps.data}>
              <XrayProvider defaultValue={showXRay}>
                {isStaging && (
                  <MetaRobots shouldFollow={false} shouldIndex={false} />
                )}
                <Head>
                  <link rel="canonical" href={canonicalUrl} />
                </Head>
                <OptimizelyProvider
                  optimizely={optimizelyClient}
                  user={{
                    id: userId,
                    attributes: {
                      locale,
                      viewed_destination: hasViewedDestination,
                      viewed_new_map: hasViewedNewDestinationMap,
                      HX_Devices:
                        typeof window !== 'undefined' &&
                        window.matchMedia('(hover: none)').matches
                          ? 'mobile'
                          : 'desktop'
                    }
                  }}
                  isServerSide={typeof window === 'undefined'}
                >
                  <Wrapper
                    isBookingFlow={isBookingFlow}
                    showHeader={showHeader}
                  >
                    {/* TODO this might not be needed with the new ribbon
                    component... yet to be determined */}
                    {showHeader && (
                      <InfoBanner data={pageProps.data?.infoBanner} />
                    )}

                    {showHeader &&
                      !isBookingFlow &&
                      pageProps.data?.megaNav && (
                        <MegaNav fixedHeader={fixedHeader} />
                      )}

                    <Component {...pageProps} />
                    {featureFlags.isOptimizeEnabled && (
                      <Script src="https://www.googleoptimize.com/optimize.js?id=OPT-TPH9T9P" />
                    )}
                    <CrimtanConsentScripts />

                    {isXrayEnabled && <ContentfulXrayControls />}
                  </Wrapper>
                </OptimizelyProvider>
              </XrayProvider>
            </SiteDataProvider>
          </StateProvider>
        </SessionProvider>
      </UserExpeditionsProvider>
    </UserProvider>
  );
}

NellieApp.componentDidCatch = (error: Error) => {
  TrackJS.track(error);
};

NellieApp.getInitialProps = async (context: AppContext) => {
  const { locale } = context.ctx;
  const theLocale = isLocale(locale) ? locale : defaultLocale;
  const contentfulLocale = mapLocaleToContenfulFormat(theLocale);

  // This is so we can have canonical urls
  const subUrl = context.ctx.req?.url;
  let localisedUrl = '';
  if (subUrl) {
    const localeUrlIndex = subUrl.indexOf(`/${theLocale}/`);
    if (localeUrlIndex !== -1) {
      [, localisedUrl] = subUrl.split(`/${theLocale}/`);
    } else {
      localisedUrl = subUrl;
    }
    /* sadly, it's possible that slugs may have '.'
        in them so split on .json */
    [localisedUrl] = localisedUrl.split('.json');
  }
  if (!localisedUrl.startsWith('/')) {
    localisedUrl = `/${localisedUrl}`;
  }

  let clientCountryCode: string | null =
    pickFirst(context.ctx.req?.headers['cf-ipcountry']) ?? '';

  if (!isValidWebsiteCountryCode(clientCountryCode)) {
    clientCountryCode = null;
  }

  const footerDataPromise = getFooter({ locale: contentfulLocale });

  const infoBannerPromise = getGlobalInfoBanner({
    locale: contentfulLocale
  });

  const getRibbonPromise = getHeaderRibbonQuery('6jjWCPzb0H9jdc3sQa6wwI', {
    locale: contentfulLocale
  });

  const getMegaNavPromise = getMegaNavQuery({
    locale: contentfulLocale
  });

  const [
    footerDataResponse,
    infoBannerDataResponse,
    getRibbonResponse,
    getMegaNavResponse
  ] = await Promise.all([
    footerDataPromise,
    infoBannerPromise,
    getRibbonPromise,
    getMegaNavPromise
  ]);

  return {
    pageProps: {
      data: {
        footer: footerDataResponse,
        infoBanner: infoBannerDataResponse,
        headerRibbon: getRibbonResponse,
        megaNav: getMegaNavResponse
      },
      localisedUrl,
      clientCountryCode
    }
  };
};

export default NellieApp;
